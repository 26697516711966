// Mobile Screen
@mixin mobile {
	@media screen And (max-width: 768px) {
		@content;
	}
}

// Tablet Screen
@mixin tablet {
	@media screen And (max-width: 992px) {
		@content;
	}
}

/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 4px;
	margin: 14px 3px;
	cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #cecece;
	border-radius: 4px;
	cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #6596dc;
	cursor: pointer;
}

.ant-modal-title {
	font-size: 26px !important;
	font-weight: bold !important;
	text-align: center !important;
	color: grey !important;
	margin-bottom: 20px !important;
}

@include mobile {
	.optionSupprission {
		flex-direction: column;
	}
}

.ant-form-item.custom-form-item {
	margin-bottom: 0px !important;
}
